$(document).ready(function($) {
	$('.navbar-toggle').on('click', function(e){
		$('#nav').toggle();
	});

	$('#search-toggle').on('click', function(e){
		$('.boxsearch').toggle();
	});

	if ($(window).width() < 1020) {
		$('.btnsearch').html('Search');
	}
});

$(window).on('resize', function(){
	if ($(window).width() < 1020) {
		$('.btnsearch').html('Search');
	}
});