$(document).ready(function() {
	var owl = $(".post-slider");
	owl.owlCarousel({
		autoplay : true,
		autoplayTimeout : 8000,
		autoplayHoverPause : true,
		loop : true,
		nav : true,
		dots : false,
		responsiveClass : true,
		responsive : {
				// breakpoint from 0 up
				0 : {
								items : 1
				},
				// breakpoint from 348 up
				348 : {
								items : 2
				},
				// breakpoint from 600 up
				600 : {
								items : 3
				},
				// breakpoint from 900 up
				900 : {
								items : 4
				},
				// breakpoint from 1000 up
				1000 : {
								items : 4
				}
		},
		navText : ["<i class='owl-left'></i>","<i class='owl-right'></i>"]
	});

	var owl1 = $(".post-slider-tablet");
	if ($(window).width() <= 768) {
		owl1.owlCarousel({
			autoplay : true,
			autoplayTimeout : 8000,
			autoplayHoverPause : true,
			loop : true,
			nav : true,
			dots : false,
			responsiveClass : true,
			responsive : {
					// breakpoint from 0 up
					0 : {
									items : 1
					},
					// breakpoint from 348 up
					348 : {
									items : 2
					},
					// breakpoint from 600 up
					600 : {
									items : 3
					},
					// breakpoint from 900 up
					900 : {
									items : 4
					},
					// breakpoint from 1000 up
					1000 : {
									items : 4
					}
			},
			navText : ["<i class='owl-left'></i>","<i class='owl-right'></i>"]
		});
	}
});