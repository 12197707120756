function makePostHomeSameHeight() {
	var highestBox = 0;
	var elementArr = [".intro", ".itemname"];

	$('.c-post-wrapper').each(function(){
		var _this = this;
		$.each( elementArr, function( key, value ) {
			highestBox = 0;
			$(_this).find('.c-post ' + value).height('auto');

			$(_this).find('.c-post ' + value).each(function() {
				if ($(this).height() > highestBox) {
					highestBox = $(this).height();
				}
			});

			if ($(window).width() > 576) {	// Chỉ căn bằng nhau trên desktop
				$(_this).find('.c-post ' + value).height(highestBox);
			}
		});
	});
}

$(document).ready(function($) {
	// makePostHomeSameHeight();

	$(window).on('load resize', function(){
		// makePostHomeSameHeight();
	});
});