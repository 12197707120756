//Lên đầu trang
function backToTop(){
	// hide #back-top first
	$("#back-top").hide();

	// fade in #back-top
	$(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('#back-top').fadeIn();
				$('#call').fadeIn();
				//fix top menu
				//$('#nav_menu').addClass('navbar-fixed-top');
			} else {
				$('#call').fadeOut();
				$('#back-top').fadeOut();
				//$('#nav_menu').removeClass('navbar-fixed-top');
			}
		});

		// scroll body to 0px on click
		$('#back-top a').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});
	});
}

$(document).ready(function(){
	backToTop();
});