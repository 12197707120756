function makePostHotelSameHeight() {
	var highestBox = 0;
	var elementArr = [".newslist"];

	$('#leftcol').each(function(){
		var _this = this;
		$.each( elementArr, function( key, value ) {
			highestBox = 0;
			$(_this).find(value).height('auto');

			$(_this).find(value).each(function() {
				if ($(this).height() > highestBox) {
					highestBox = $(this).height();
				}
			});

			if ($(window).width() >= 400 && $(window).width() <= 1100) {	// Chỉ căn bằng nhau trên desktop
				$(_this).find(value).height(highestBox);
			}
		});
	});
}

$(document).ready(function($) {
	// makePostHotelSameHeight();

	$(window).on('load resize', function(){
		// makePostHotelSameHeight();
	});
});